/* global Turbolinks */
import { Controller } from 'stimulus';
import './pagy.scss';

export default class extends Controller {
  static values = { pageParam: String }
  navigate(event){
    const page = event.target.dataset.page
    const urlParams = new URLSearchParams(window.location.search);

    urlParams.set(this.pageParamValue, page)
    Turbolinks.visit(`${window.location.pathname}?${urlParams.toString()}`)
  }
}