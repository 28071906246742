import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import { Application } from "stimulus"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import 'css/tailwind.scss';
import 'css/global.scss';
import 'animate.css';

Rails.start()
Turbolinks.start()
ActiveStorage.start()

const application = window.application = Application.start()
export default application