import ReflexController from '../../../frontend/controllers/reflex_controller'
import './balance.scss'

export default class extends ReflexController {

  connect() {
    super.connect()
    this.reloadBalance = () => {
      this.stimulate('Business::BalanceComponent#reload_balance')
    }
    document.addEventListener('userUnlocked', this.reloadBalance)
  }

  disconnect() {
    document.removeEventListener('userUnlocked', this.reloadBalance, false)
  }
}
