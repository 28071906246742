import { Controller } from 'stimulus';
import 'css/app/recruiter/company.scss';
import Pristine from 'pristinejs';

export default class extends Controller {

  static targets = [ "form", "button" ]

  connect() {
  }

  inputChanged() {
    if (this.buttonTarget.classList == 'company__button company__button--disable') {
      this.enableButton(this.buttonTarget)
      }
  }

  enableButton(button) {
    button.disabled = false
    button.classList.remove('company__button--disable')
    button.classList.add('company__button--active')
  }
}
