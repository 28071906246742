import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    try {
      posthog.identify(this._id(), this._properties())
    } catch (error) {
      console.warn("Posthog is not available on the current environment.")
    }
  }

  _properties() {
    const properties = {
      email: this.element.dataset.userEmail
    }
    const name = this.element.dataset.userName
    return name ? { ...properties, name } : properties
  }

  _id() {
    const id = this.element.dataset.userId
    const kind = this.element.dataset.userKind
    return `${kind}:${id}`
  }
}
