import { Controller } from 'stimulus';
import './filter_pill.scss';

export default class extends Controller {
  static values = {
    active: Boolean,
    activeClass: String,
    stepIndex: Number,
    modalId: String,
  }
  connect() {
    this.element.classList.toggle(this.activeClassValue, this.activeValue);

    this.openSearchModal = (event) => {
      if(event.detail && 
        event.detail.modalId == this.modalIdValue &&
        event.detail.stepIndex === this.stepIndexValue) {
          const searchEvent = new CustomEvent(
            'openSearchModal', 
            { detail: { modalId: this.modalIdValue, stepIndex: this.stepIndexValue } })
          document.dispatchEvent(searchEvent)
      }
    }
    document.addEventListener('borealModalOpened', this.openSearchModal)
  }

  disconnect() {
    document.removeEventListener('borealModalOpened', this.openSearchModal, false)
  }

  openStep() {
    const modalEvent = new CustomEvent('openBorealModal', { detail: { modalId: this.modalIdValue, stepIndex: this.stepIndexValue } })
    document.dispatchEvent(modalEvent)
  }
}