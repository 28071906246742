/* global Turbolinks */
import { Controller } from 'stimulus';
import 'css/app/recruiter/register.scss';
import 'css/app/recruiter/session.scss';
import 'css/app/recruiter/company.scss';

export default class extends Controller {
  connect() {
    
  }
}