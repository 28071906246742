/* global Turbolinks */
import { Controller } from 'stimulus';
import 'css/app/talent/apply_modal.scss';

export default class extends Controller {

  static targets = [ 'form', 'statusInput' ]

  connect() {
  }

  confirm() {
    this.statusInputTarget.checked = true
    this.disableButtons()
    this.formTarget.submit()
  }

  reject() {
    this.statusInputTarget.checked = false
    this.disableButtons()
    this.formTarget.submit()
  }

  disableButtons() {
    this.formTarget.querySelectorAll('button').forEach((button) => {
      button.disabled = true
    })
  }
}