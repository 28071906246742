/* global Turbolinks */
import { Controller } from 'stimulus';
import Sortable from 'sortablejs';
import Rails from "@rails/ujs";

export default class extends Controller {

  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.onEnd.bind(this)
    })
  }

  onEnd(event) {
    const contentCategoryId = event.item.dataset.id
    const url = this.data.get('url').replace(':id', contentCategoryId)
    const data = new FormData()
    data.append('position', event.newIndex + 1)
    Rails.ajax({ url, type:'PUT', data })
    sessionStorage.setItem('scroll', document.scrollingElement.scrollTop);
  }
}
