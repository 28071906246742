/* global Turbolinks */
import { Controller } from 'stimulus';
import './item.scss'

export default class extends Controller {
  
  connect() {
    
  }
}
