/* global Turbolinks */
import { Controller } from 'stimulus';
import MicroModal from 'micromodal';
import './modal.scss'
export default class extends Controller { 

  static values = { id: String }

  connect() {
    MicroModal.init({
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true
    })
    this.openBorealModal = (event) => {
      if (event.detail && this.idValue == event.detail.modalId) {
        this.showModal(event.detail)
      }
    } 
    document.addEventListener('openBorealModal', this.openBorealModal)
  }

  disconnect() {
    document.removeEventListener('openBorealModal', this.openBorealModal, false)
  }

  showModal(detail) {
    MicroModal.show(`${detail.modalId}`, {
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      onShow: () => this.emitShowEvent(detail),
      onClose: () => this.emitCloseEvent(detail),
    })
  }

  show(event) {
    event.detail.modalId = event.target.dataset.modalId
    this.showModal(event.detail)
  }

  emitShowEvent(detail) {
    const event = new CustomEvent('borealModalOpened', { detail: detail })
    document.dispatchEvent(event)
  }

  emitCloseEvent(detail) {
    const event = new CustomEvent('borealModalClosed', { detail: detail })
    document.dispatchEvent(event)
  }

  close(event) {
    const id = event.target.dataset.modalId
    MicroModal.close(`${id}`);
  }
}