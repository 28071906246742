import { Controller } from "stimulus";
import './support_button.scss';

export default class extends Controller {
  static targets = [ "button" ]
  connect() {
  }

  showInfo(event) {
    this.buttonTransition()
  }

  buttonTransition() {
    this.buttonTarget.classList.toggle('inner-support--open')
  }

}
