import { Controller } from 'stimulus';
import './iterations/show.scss'

export default class extends Controller {

  static values = {
    percentage: Number,
    maxTotal: Number
  }

  static targets = ['progressBar', 'percentage', 'total']

  connect() {
  }

  percentageChanges() {
    const sum = this.getTotal()
    this.updateProgressBar(sum) 
  }

  getTotal() {
    let sum = 0;
    this.percentageTargets.forEach((percentage) => {
      const input = percentage.querySelector('input')
      const label = percentage.querySelector('.boreal-range-field__label')
      const absoluteValue = Math.round(this.totalTarget.value * input.value / 100)
      label.innerHTML = `${percentage.dataset.label} ${absoluteValue} jobs`
      sum += parseInt(input.value);
    })
    return sum;
  }

  updateProgressBar(sum) {
    const input = this.progressBarTarget.querySelector('input')
    const progressBar = this.progressBarTarget.querySelector('.boreal-range-field__progress')
    const value = this.progressBarTarget.querySelector('.boreal-range-field__value')
    const width = sum > 100 ? 100 : sum
    
    input.value = sum
    progressBar.style.width = width + '%'
    value.innerHTML = sum 
  }
}