import ReflexController from '../../../frontend/controllers/reflex_controller'
import './simple_top_navbar.scss'

export default class extends ReflexController {

  static targets = [ "popover" ]

  connect() {
    super.connect()
  }

  avatarClicked() {
    this.popoverTarget.classList.toggle('business-simple-top-navbar__popover--hide');
  }
}
