/* global Turbolinks */
import ApplicationController from 'controllers/rails/application_controller.js';
import 'css/app/support.scss';

export default class extends ApplicationController {
  static targets = [ 'form', 'bug', 'idea', 'request', 'bugInput', 'ideaInput', 'requestInput' ]
  optionChanged(event) {
    this.resetAllSections()
    this.setSection(event.target, event.target.defaultValue)
  }

  resetAllSections() {
    this.formTarget.reset()
    this.hideAllSections()
    this.resetRequiredInputs()
  }

  hideAllSections(){
    this.bugTarget.style.display = 'none';
    this.ideaTarget.style.display = 'none';
    this.requestTarget.style.display = 'none';
  }

  resetRequiredInputs(){
    this.bugInputTargets.forEach(element => element.removeAttribute('required'));
    this.ideaInputTargets.forEach(element => element.removeAttribute('required'));
    this.requestInputTargets.forEach(element => element.removeAttribute('required'));
  }

  setSection(target, value) {
    target.checked = true
    if (value == 'bug') return this.setBugSection()
    if (value == 'idea') return this.setIdeaSection()
    if (value == 'feature') return this.setSolicitudSection()
  }

  setBugSection() {
    this.bugTarget.style.display = 'block'
    this.bugInputTargets.forEach(element => element.setAttribute('required', ''))
  }

  setIdeaSection() {
    this.ideaTarget.style.display = 'block'
    this.ideaInputTargets.forEach(element => element.setAttribute('required', ''))
  }

  setSolicitudSection() {
    this.requestTarget.style.display = 'block'
    this.requestInputTargets.forEach(element => element.setAttribute('required', ''))
  }
}
