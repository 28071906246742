import { Controller } from 'stimulus';
import './filter_by_video.scss'

export default class extends Controller {

  static values = {
    activeClass: String
  }

  connect() {
    this.setUrlParams()
    const filter = this.urlParams.get('with_video')
    this.element.classList.toggle(this.activeClassValue, filter == "true")
  }

  setUrlParams() {
    const queryString = window.location.search;
    this.urlParams = new URLSearchParams(queryString);
  }

  filter() {
    const filter = this.urlParams.get('with_video')
    filter == "true" ? this.urlParams.delete('with_video') : this.urlParams.set('with_video', 'true')
    
    this.urlParams.delete('page')
    Turbolinks.visit(`${window.location.pathname}?${this.urlParams.toString()}`)
  }
}
