import { Controller } from "stimulus";
import './file_field.scss'

export default class extends Controller {
  static targets = [ 'input', 'display', 'preview', 'buttonBody' ]

  connect() {
    this.updateState(this.inputTarget)
  }
  
  click() {
    this.inputTarget.click()
  }

  fileUploaded(event){
    this.updateState(event.target)
    if (!this.hasPreviewTarget || !this.hasDisplayTarget) return 
    const file = event.target.files[0]
    if (file) {
      this.buttonBodyTarget.classList.add('boreal-file-field__body--filled')
      this.previewTarget.innerHTML = file.name;
      this.previewTarget.classList.remove('boreal-file-field__preview--hidden')
      this.displayTarget.style.display = 'none'
      this.displayTarget.classList.add('boreal-file-field__display--hidden')
    }
    else {
      this.buttonBodyTarget.classList.remove('boreal-file-field__body--filled')
      this.previewTarget.innerHTML = '';
      this.previewTarget.classList.add('boreal-file-field__preview--hidden')
      this.displayTarget.style.display = 'flex'
      this.displayTarget.classList.remove('boreal-file-field__display--hidden')
    }
  }

  updateState(field){
    field.dataset.filled = (field.dataset.default == "true" || field.files.length > 0)
  }
}
