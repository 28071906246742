import { Controller } from "stimulus";
import 'css/app/talent/authentication.scss';
import Pristine from 'pristinejs';
import Swiper, { Navigation, EffectFade } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css';

export default class extends Controller {
  
  static targets = [ 'slider', 'buttonPrev', 'buttonNext', 'signUpForm', 'signUpButton', 'signInForm', 'signInButton' ]
  
  connect() {
    super.connect()
    this.initParams()
    this.swiper = new Swiper(this.sliderTarget, this.swiperParams )
  }

  initParams(){
    this.swiperParams = {
      modules: [ Navigation, EffectFade ],
      init: true,
      speed: 600,
      slideClass: 'talent-authentication__container',
      allowTouchMove: false,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      navigation: {
        nextEl: this.buttonNextTarget,
        prevEl: this.buttonPrevTarget,
      }
    }
  }

  slideToSignUp(){
    this.swiper.slideTo(0)
  }

  slideToSignIn(){
    this.swiper.slideTo(2)
  }

  signUpInputChanged() {
    this.checkForm(this.signUpFormTarget, this.signUpButtonTarget)
  }

  signInInputChanged() {
    this.checkForm(this.signInFormTarget, this.signInButtonTarget)
  }

  checkForm(form, button) {
    const pristine = new Pristine(form);
    if (pristine.validate()) {
      this.enableButton(button)
    }
    else{
      this.disableButton(button)
    }
  }

  enableButton(button) {
    button.disabled = false
    button.classList.remove('talent-authentication__button--disable')
    button.classList.add('talent-authentication__button--active')
  }

  disableButton(button) {
    button.disabled = true
    button.classList.remove('talent-authentication__button--active')
    button.classList.add('talent-authentication__button--disable')
  }
}
