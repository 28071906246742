import ReflexController from '../../../frontend/controllers/reflex_controller'
import NiceSelect from "nice-select2/dist/js/nice-select2.js";
import "./select.scss"
import "nice-select2/dist/css/nice-select2.css"

export default class extends ReflexController {

  static targets = [ 'select' ]

  connect() {
    this.niceSelect = new NiceSelect(this.selectTarget, {searchable: true})
    if(this.selectTarget.multiple) this.selectTarget.options[0].remove()
    const selectedValues = [...this.selectTarget.selectedOptions].map(option => option.value)
    this.updateDisplay(selectedValues)
  }

  disconnect() {
    this.niceSelect.destroy()
  }

  change(){
    this.updateDisplay([...this.selectTarget.selectedOptions].map(option => option.value).filter(value => value != ""))
  }

  updateDisplay(values) {
    this.selectTarget.multiple ? this.updateDisplayMultiselect(values) : this.updateDisplaySimple(values)
  }

  updateDisplayMultiselect(values){
    const options = [...this.selectTarget.options].filter( option => values.includes(option.value) )
    const text = options.length > 0 ? options.map(option => option.text).join(", ") : this.selectTarget.getAttribute("placeholder")
    this.element.getElementsByClassName("multiple-options")[0].innerHTML = text
  }

  updateDisplaySimple(values){
    const option = [...this.selectTarget.options].find( option => option.value == values[0] )
    const text = option.text.length > 0 ? option.text : this.selectTarget.getAttribute("placeholder")
    this.element.getElementsByClassName("current")[0].innerHTML = text
  }
}
