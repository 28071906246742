/* global Turbolinks */
import ApplicationController from 'controllers/rails/application_controller.js';
import 'css/app/content.scss';
import 'css/app/content/header.scss';
import 'css/app/content/blog.scss';
import 'css/app/content/typeform.scss';
import marked from 'marked'
import 'css/app/markdown_styles'

export default class extends ApplicationController {

  static targets = [ 'content', 'type' ]

  connect() {
    if (this.typeTarget.dataset.value === "blog") {
      const content = this.contentTarget.dataset.content
      this.contentTarget.innerHTML = marked(content)
    }
  }

  shareModal(event) {
    const key = event.currentTarget.dataset.key
    MicroModal.show(`share-modal-${key}`);
  }
}