import ApplicationController from 'controllers/rails/application_controller.js';
import 'css/app/jobs/redirection.scss';
import Turbolinks from 'turbolinks';

export default class extends ApplicationController {


  connect() {
    const url = this.element.dataset.url;
    if (!url) return;
    this.redirectAction = setTimeout(() => Turbolinks.visit(url), 3000);
  }

  disconnect() {
    clearTimeout(this.redirectAction);
  }

  cancelAction() {
    clearTimeout(this.redirectAction);
  }
}