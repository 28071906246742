/* global Turbolinks */
import ApplicationController from 'controllers/rails/application_controller.js';
import 'css/app/business/purchases.scss';

export default class extends ApplicationController {

  static targets = [ 'monthDisplay', 'yearDisplay' ]
  
  connect() {
    const scriptTag = document.createElement('script')
    scriptTag.setAttribute( 'src', 'https://api.paycomet.com/gateway/paycomet.jetiframe.js?lang=es')
    document.body.appendChild( scriptTag )
  }

  monthChanged(event){
    this.monthDisplayTarget.textContent = event.target.value
  }

  yearChanged(event){
    this.yearDisplayTarget.textContent = event.target.value
  }
  
}