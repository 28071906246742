/* global Turbolinks */
import { Controller } from 'stimulus';
import MicroModal from 'micromodal';
import 'css/app/modal.scss';
import 'css/app/share_modal.scss';

export default class extends Controller {

  connect() {

  }

  whatsapp() {
    this.close()
    const message = this.data.get('key') == 'invite' ?  this.whatsappMessageInvite(): this.whatsappMessage()
    const whatsappTo = `https://api.whatsapp.com/send?phone=&text=${message}`
    document.location.href = whatsappTo;
  }

  whatsappMessage() {
    const title = this.emailTitleMessage()
    const link = encodeURIComponent(this.sharedLink('whatsapp'))
    return `Aprovecha gratis el contenido sobre: ${title} e impulsa tu carrera profesional ${link}`
  }

  whatsappMessageInvite(){
    const link = encodeURIComponent(this.sharedLink('whatsapp'));
    return `¿Aún no has probado aurora? ¡Prueba gratis aurora y aprende cómo impulsar tu carrera profesional! ${link}`;
  }

  email() {
    this.close()
    const key = this.data.get('key');
    const link = this.sharedLink('email');
    if(key == 'invite'){
      const subject = encodeURIComponent(`¿Aún no has probado aurora?`);
      const body = encodeURIComponent(`¡Prueba gratis aurora y aprende cómo impulsar tu carrera profesional! ${link}`);
      document.location.href = `mailto: ?subject=${subject}&body=${body}` ;
    }else{
      document.location.href = `mailto: ?subject=${this.emailTitleMessage()}&body=${this.emailBodyMessage()}`;
    }
  }

  emailTitleMessage () {
    const title = this.data.get('messageTitle')
    return encodeURIComponent(title)
  }

  emailBodyMessage() {
    const link = this.sharedLink('email')
    return encodeURIComponent(`¿Estás a la última en conocimientos para impulsar tu carrera profesional?\n\nAprovecha gratis nuestras series de contenido creadas para ti: ${link}\n\n`)
  }

  sharedLink(medium) {
    const link = this.data.get('link')
    return `${link}?utm_source=app&utm_medium=${medium}&utm_campaign=referral`
  }

  close() {
    const key = this.data.get('key')
    MicroModal.close(`share-modal-${key}`);
  }
}