import ReflexController from '../../../frontend/controllers/reflex_controller'
import './image_field.scss'

export default class extends ReflexController {
  
  static targets = [ 'input', 'preview', "empty" ]
  
  connect() {
    super.connect()
  }
  
  clicked() {
    this.inputTarget.click()
  }
  
  upload() {
    const file = this.inputTarget.files[0]
    file ? this.setPreview(file) : this.hidePreview()
  }

  showPreview() {
    this.previewTarget.classList.remove("image-field__preview--hidden")
    this.emptyTarget.classList.add("image-field__empty--hidden")
  }

  hidePreview() {
    this.previewTarget.classList.add("image-field__preview--hidden")
    this.emptyTarget.classList.remove("image-field__empty--hidden")
  }
  
  setPreview(file) {
    const reader = new FileReader()
    reader.addEventListener( "load", ()=> {
      this.previewTarget.setAttribute("src", reader.result);
    })
    reader.readAsDataURL(file);
    this.showPreview()
  }
}
