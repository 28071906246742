import ReflexController from '../../../frontend/controllers/reflex_controller'
import "./search.scss"

export default class extends ReflexController {

  static targets = [ 'input', 'loader' ]

  static values = {
    key: String
  }

  connect() {
    this.handleURL()
    this.initParmeter()
    this.inputTarget.focus()
  }

  handleURL() {
		const queryString = window.location.search;
    this.urlParams = new URLSearchParams(queryString);
	}

  initParmeter() {
    this.search = this.urlParams.get(this.keyValue)
		this.initInput()
  }

  initInput() {
    if(this.search) {
      this.inputTarget.value = this.search
    }
	}

  submit() {
    this.urlParams.delete(this.keyValue)
    this.urlParams.delete("page")
    if (this.inputTarget.value.trim()) this.urlParams.set(this.keyValue, this.inputTarget.value.trim())
    Turbolinks.visit(`${window.location.pathname}?${this.urlParams.toString()}`)
  }

  debounce() {
    clearTimeout(this.submitTimeOut)
    if (this.inputTarget.value.trim() || this.search) {
      this.showCharge()
      this.submitTimeOut = setTimeout(() => this.submit(), 1000);
    }
  }

  showCharge() {
    this.loaderTarget.style.display = 'block'
  }
}
