/* global Turbolinks */
import ReflexController from '../reflex_controller';
import './fields/store.scss'

export default class extends ReflexController {
  static targets = ['editSection', 'showSection' ]

  show() {
    this.editSectionTarget.classList.add('hidden')
    this.showSectionTarget.classList.remove('hidden')
  }

  edit() {
    this.editSectionTarget.classList.remove('hidden')
    this.showSectionTarget.classList.add('hidden')
  }
}