import { Controller } from 'stimulus'
import './share.scss'

export default class extends Controller {
  
  static targets = ["url","button"]

  copyUrl(event) {
    navigator.clipboard.writeText(this.urlTarget.value)
      .then(() => {
        this.buttonTarget.innerHTML = t('jobs.share_component.copied')
        setTimeout(() => {
          event.target.textContent = t('jobs.share_component.button')
        }, 3000);
      })
  }

  resetCopyButton(event) {
    this.buttonTarget.innerHTML = t('jobs.share_component.button')
  }
}