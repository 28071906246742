/* global Turbolinks */
import { Controller } from 'stimulus';

export default class extends Controller {
  
  static targets = [ 'warning' ]
  
  connect() {
  }
  
  refresh(event) {
    const currentLength = event.target.value.length
    const maxLength = this.element.dataset.max
    const valid = currentLength <= maxLength

    this.warningTarget.innerHTML = this.prompt(valid)
  }
  
  prompt(valid) {
    return valid ? '' : 'Procura mantener las descripciones concisas'
  }
}