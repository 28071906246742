/* global Turbolinks */
import { Controller } from 'stimulus';
import 'css/app/devise/register.scss';
import 'css/app/devise/hunters_register.scss';
import Pristine from 'pristinejs';
import PanelSnap from 'panelsnap';

export default class extends Controller {

  static targets = [ "panel", "form", "button" ]

  get currentPanelIndex() {
    return this.panelSnap.panelList.indexOf(this.panelSnap.activePanel)
  }

  connect() {
    if(this.hasPanelTarget) this.initPanelSnap()
  }

  initPanelSnap() {
    this.panelSnap = new PanelSnap({
      container: this.panelTarget,
      panelSelector: '> div',
      directionThreshold: 50,
      delay: 0,
      duration: 300,
      easing: function(t) { return t },
    });
  }

  nextPanel(){
    const nextPanel = this.panelSnap.panelList[this.currentPanelIndex + 1]
    this.panelSnap.snapToPanel(nextPanel)
  }

  prevPanel(){
    const prevPanel = this.panelSnap.panelList[this.currentPanelIndex - 1]
    this.panelSnap.snapToPanel(prevPanel)
  }

  inputChanged() {
    const pristine = new Pristine(this.formTarget);
    if (pristine.validate()) {
      this.enableButton(this.buttonTarget)
    }
    else{
      this.disableButton(this.buttonTarget)
    }
  }

  enableButton(button) {
    button.disabled = false
    button.classList.remove('register__button--disable')
    button.classList.add('register__button--active')
  }

  disableButton(button) {
    button.disabled = true
    button.classList.remove('register__button--active')
    button.classList.add('register__button--disable')
  }
}
