/* global Turbolinks */
import ReflexController from '../../../../frontend/controllers/reflex_controller'
import './favourite.scss'

export default class extends ReflexController {
  
  connect() {
    super.connect()
  }

  updateFavouriteStatus() {
    this.stimulate('Talent::Job::FavouriteComponent#update_favourite_status')
  }
}
