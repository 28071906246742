import ReflexController from '../../../frontend/controllers/reflex_controller'
import './talent_contact.scss'

export default class extends ReflexController {

  static targets = ['button']

  connect() {
    super.connect()
  }

  afterReflex() {
    this.buttonTarget.dataset.contacted == "contacted" ? this.emitEvent('contact') : this.emitEvent('uncontact')
  }

  toggleContact() {
    this.stimulate('Business::TalentContactComponent#toggle_talent_contact')
  }

  emitEvent(name) {
    const userId = this.element.dataset.userId
    const customEvent = new CustomEvent(`${name}User`, { detail: { userId } })
    document.dispatchEvent(customEvent)
  }
  
}
