/* global Turbolinks */
import ApplicationController from 'controllers/rails/application_controller.js';
import 'css/app/learning.scss';
import 'css/app/learnings.scss';

export default class extends ApplicationController {
  static targets = ['applySection'];

  connect() {
    if(this.applySectionTargets.length == 2) this.manageABTesting()
  }

  manageABTesting() {
    if(!!window.posthog && window.posthog.isFeatureEnabled('learning-authentication')) {
      const nodeToDestroy = this.applySectionTargets.find((node) => node.dataset.abTest === 'B')
      nodeToDestroy.remove()
    } else {
      const nodeToDestroy = this.applySectionTargets.find((node) => node.dataset.abTest === 'A')
      nodeToDestroy.remove()
    }
  }

  scrollToApply() {
    this.applySectionTarget.scrollIntoView({ behavior: 'smooth' });
  }
}