/* global Turbolinks */
import { Controller } from 'stimulus';
import './filters.scss'

export default class extends Controller {

  static targets = [ 'button' ]

  static values = {
    filterKey: String,
    multiple: Boolean
  }

  connect() {
    this.handleURL()
    this.initParmeter()
  }

  initParmeter() {
    this.filters = this.urlParams.getAll(`${this.filterKeyValue}`)
		this.toggleFilters()
  }

  toggleFilters() {
    if (this.hasButtonTarget) return this.toggleButtonFilters()
	}

  toggleButtonFilters() {
    this.buttonTargets.forEach( element => element.classList.remove('boreal-filters__button--active'))
		for (const filter of this.filters) {
      const element = this.buttonTargets.find(filterTarget => filterTarget.dataset.enum===filter)
      element.classList.add('boreal-filters__button--active')
    }
  }

  handleURL() {
		const queryString = window.location.search;
    this.urlParams = new URLSearchParams(queryString);
	}

  filter(event) {
    const value = event.target.dataset.enum
    this.buildFilteredURL(value)
    this.visit()
  }

  filterByMultiselect(event) {
    const values = [...event.target.selectedOptions].map(option => option.value);
    this.urlParams.delete(this.filterKeyValue)
    values.forEach( filterValue => this.urlParams.append(this.filterKeyValue, filterValue))
    this.visit()
  }

  filterBySelect(event) {
    const value = event.target.value
    this.buildFilteredURL(value)
    this.visit()
  }

  buildFilteredURL(value) {
    if(this.multipleValue) return this.buildMultipleFilteredURL(value)
    this.buildSingleFilteredURL(value)
  }

  buildSingleFilteredURL(value) {
    const filtersApplied = this.urlParams.getAll(this.filterKeyValue)
		const isValueApplied = filtersApplied.find( filter => filter == value)
    this.urlParams.delete(this.filterKeyValue)
    if(isValueApplied) return 
    this.urlParams.set(this.filterKeyValue, value)
  }

  buildMultipleFilteredURL(value) {
		const filtersApplied = this.urlParams.getAll(this.filterKeyValue)
		const isValueApplied = filtersApplied.find( filter => filter == value)
		this.urlParams.delete(this.filterKeyValue)
    if(isValueApplied) {
      const finalFilters = filtersApplied.filter(filter => filter !== value)
      finalFilters.forEach( filterValue => this.urlParams.append(this.filterKeyValue, filterValue))
    } else {
      filtersApplied.push(value)
      filtersApplied.forEach( filterValue => this.urlParams.append(this.filterKeyValue, filterValue))
    }
	}

  visit(){
    Turbolinks.visit(`${window.location.pathname}?${this.urlParams.toString()}`)
  }
}