import { Controller } from "stimulus";
import './text_area.scss'

export default class extends Controller {
  static targets = [ 'counter' ]
  static values = { limit: Number }

  connect() {
  }

  textChanged(event){
    var textLength = event.target.value.length
    this.counterTarget.textContent = `${this.limitValue - textLength}`
    if (textLength > this.limitValue){
      this.counterTarget.classList.add('text-area__counter--overlimit')
    } else {
      this.counterTarget.classList.remove('text-area__counter--overlimit')
    }
  }
}
