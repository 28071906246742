import { Controller } from 'stimulus'
import "./progress_bar.scss"

export default class extends Controller {

  static targets = [ "line", "stepLink" ]

  connect() {
    //youtube.com/watch?v=8aGhZQkoFbQ
    setTimeout(() => this.lineTarget.style.width = this.element.dataset.width, 0);

    if (this.element.dataset.firstIncompletePosition && this.hasStepLinkTarget) this.disableLinksAfterFirstIncompletePosition()
  }

  disableLinksAfterFirstIncompletePosition() {
    this.stepLinkTargets.forEach((stepLink, index) => {
      if (index >= this.element.dataset.firstIncompletePosition) {
        stepLink.href = ""
        stepLink.classList.add("aura-forms-progress-bar__item--disabled")
        stepLink.classList.remove("turbolinks-animate")
        stepLink.dataset.turbolinksAnimation = ""
        stepLink.dataset.turbolinksAnimationTarget = ""
      }
    })
  }
}