/* global Turbolinks */
import { Controller } from 'stimulus';
import 'css/app/devise/session.scss';
import 'css/app/devise/hunters_session.scss';
import 'css/app/recruiter/session.scss';
import Pristine from 'pristinejs';

export default class extends Controller {

  static targets = [ "form", "button" ]

  connect() {
  }

  inputChanged() {
    const pristine = new Pristine(this.formTarget);
    if (pristine.validate()) {
      this.enableButton(this.buttonTarget)
    }
    else{
      this.disableButton(this.buttonTarget)
    }
  }

  enableButton(button) {
    button.disabled = false
    button.classList.remove('session__button--disable')
    button.classList.add('session__button--active')
    button.classList.remove('recruiter-sessions__button--disable')
    button.classList.add('recruiter-sessions__button--active')
  }

  disableButton(button) {
    button.disabled = true
    button.classList.remove('session__button--active')
    button.classList.add('session__button--disable')
    button.classList.remove('recruiter-sessions__button--active')
    button.classList.add('recruiter-sessions__button--disable')
  }
}
