import { Controller } from 'stimulus';
import './pill.scss';

export default class extends Controller {
  static values = {
    filterKey: String,
    filterValue: String
  }
  connect() {
    this.setUrlParams()
  }

  setUrlParams() {
    const queryString = window.location.search;
    this.urlParams = new URLSearchParams(queryString);
  }

  remove() {
    this.urlParams.delete(this.filterKeyValue, this.filterValueValue)
    this.urlParams.delete('page')
    Turbolinks.visit(`${window.location.pathname}?${this.urlParams.toString()}`)
  }
}