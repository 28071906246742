import ApplicationController from 'controllers/rails/application_controller.js';
import 'css/app/talent/applicants.scss';

export default class extends ApplicationController {

  static values = { activeTabClass: String }
  static targets = [ 'activeTab', 'inactiveTab' ]

  connect() {
    this.handleURL()
    this.getStatus()
  }

  handleURL() {
		const queryString = window.location.search;
    this.urlParams = new URLSearchParams(queryString);
	}

  getStatus() {
    const status = this.urlParams.get('status')
    this.toggleTabs(status)
  }

  toggleTabs(status) {
    const isActiveJobsTabActive = status === null || status === 'active'
    this.activeTabTarget.classList.toggle(this.activeTabClassValue, isActiveJobsTabActive)
    this.inactiveTabTarget.classList.toggle(this.activeTabClassValue, !isActiveJobsTabActive)
  }
}