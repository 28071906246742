import { Controller } from 'stimulus';
import './prospects/store.scss'

export default class extends Controller {
  static targets = ['message']

  connect() {
  }

  updateMessage(event) {
    const inputType = event.target.type
    const reason = inputType === 'radio' ? event.target.dataset.value : event.target.value

    this.messageTargets.forEach((message) => {
      message.value = reason
    })
  }
}
