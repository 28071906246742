import { Controller } from 'stimulus'
import './slider.scss'
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  EffectCards,
  EffectCoverflow
} from 'swiper'
import 'swiper/swiper.scss'
import 'swiper/swiper-bundle.css'

export default class extends Controller {
  static targets = [
    'buttonPrev',
    'buttonNext',
    'buttonPrevContainer',
    'buttonNextContainer',
    'pagination'
  ]

  static classes = ['buttonContainerDisabled']

  static values = {
    spaceBetween: Number,
    autoplay: Boolean,
    effect: String,
    pagination: Boolean,
    navigation: Boolean
  }

  connect() {
    super.connect()
    const modules = this.setInitModules()
    this.initParams(modules)
    this.setModuleParams(modules)
    this.swiper = new Swiper(this.element, this.swiperParams)
    this.swiper.on('init', () => {
      this.checkButtons()
    })
    this.swiper.on('slideChange', () => {
      this.checkButtons()
    })
    this.swiper.init()
  }

  setInitModules() {
    const modules = []
    if (this.navigationValue) modules.push(Navigation)
    if (this.paginationValue) modules.push(Pagination)
    if (this.autoplayValue) modules.push(Autoplay)
    if (this.effectValue === 'cards') modules.push(EffectCards)
    if (this.effectValue === 'coverflow') modules.push(EffectCoverflow)
    return modules
  }

  initParams(modules) {
    this.swiperParams = {
      modules: modules,
      init: false,
      speed: 600,
      slideClass: 'boreal-slider__slide'
    }
  }

  setModuleParams(modules) {
    if (modules.includes(Navigation)) this.setNavigation()
    if (modules.includes(Pagination)) this.setPagination()
    if (modules.includes(Autoplay)) this.setAutoplay()
    if (modules.includes(EffectCards)) this.setEffectCardsParams()
    if (modules.includes(EffectCoverflow)) this.setEffectCoverflowParams()
    if (!modules.includes(EffectCards) && !modules.includes(EffectCoverflow))
      this.setCarouselParams()
  }

  setNavigation() {
    this.swiperParams.navigation = {
      nextEl: this.buttonNextTarget,
      prevEl: this.buttonPrevTarget,
      disabledClass: 'boreal-slider__button--disabled'
    }
  }

  setPagination() {
    this.swiperParams.pagination = {
      el: this.paginationTarget,
      type: 'bullets',
      clickable: true,
      dynamicBullets: false
    }
  }

  setAutoplay() {
    this.swiperParams.autoplay = {
      delay: 5000
    }
  }

  setCarouselParams() {
    Object.assign(this.swiperParams, {
      spaceBetween: Number(this.spaceBetweenValue),
      slidesPerView: 'auto',
      slidesPerGroupAuto: true
    })
  }

  setEffectCardsParams() {
    Object.assign(this.swiperParams, {
      effect: 'cards',
      perSlideOffset: 8,
      perSlideRotate: 2,
      rotate: true,
      slideShadows: true,
      grabCursor: true
    })
  }

  setEffectCoverflowParams() {
    Object.assign(this.swiperParams, {
      effect: 'coverflow',
      depth: 100,
      modifier: 1,
      rotate: 50,
      scale: 0.8,
      slideShadows: true,
      stretch: 10
    })
  }

  checkButtons() {
    if (
      !this.hasButtonPrevContainerTarget ||
      !this.hasButtonNextContainerTarget
    )
      return
    const prevClassList = this.buttonPrevContainerTarget.classList
    const nextClassList = this.buttonNextContainerTarget.classList
    const disabledClass = this.buttonContainerDisabledClass
    this.swiper.isBeginning
      ? prevClassList.add(disabledClass)
      : prevClassList.remove(disabledClass)
    this.swiper.isEnd
      ? nextClassList.add(disabledClass)
      : nextClassList.remove(disabledClass)
  }
}
