import '@simonwep/pickr/dist/themes/monolith.min.css';
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js"
import "flatpickr/dist/themes/airbnb.css";
import { Controller } from 'stimulus';
import "./date_picker.scss"
export default class extends Controller {

  static targets = ['calendar', 'startDate', 'endDate'];
  static values = { range: Boolean };

  connect() {
    if(this.rangeValue) {
      this.flatpickr = flatpickr(this.calendarTarget, {
        mode: 'range',
        dateFormat: 'Y-m-d',
        locale: Spanish,
        maxDate: new Date().fp_incr(30),
        defaultDate: [this.startDateTarget.value, this.endDateTarget.value],
        onChange: function(selectedDates, dateStr, instance) {
          if(selectedDates.length > 1) {
            this.startDateTarget.value = selectedDates[0].toLocaleString().split(',')[0].replaceAll("/", "-");
            this.endDateTarget.value = selectedDates[1].toLocaleString().split(',')[0].replaceAll("/", "-");
          }
        }.bind(this)
      });
    } else {
      this.flatpickr = flatpickr(this.calendarTarget, {
        dateFormat: 'Y-m-d',
        maxDate: new Date().fp_incr(30)
      });
    }
  }

  disconnect() {
    this.flatpickr.destroy();
  }
}