import application from '../config/application'

import restoreScrollTurbolinks from '../config/restore_scroll_turbolinks'
import {
  setLinkAnimation,
  executeAnimation
} from '../config/turbolinks_animation'

import loadLegacyDefinitions from '../config/definitions/legacy'
import loadBorealDefinitions from '../config/definitions/boreal'
import loadAuraDefinitions from '../config/definitions/aura'
import loadBusinessDefinitions from '../config/definitions/business'
import loadTalentDefinitions from '../config/definitions/talent'
import loadStaffDefinitions from '../config/definitions/staff'
import loadAuroraHuntersDefinitions from '../config/definitions/aurora_hunters'
import loadConceptsDefinitions from '../config/definitions/concepts'

import loadStimulusReflex from '../config/stimulus_reflex'
loadStimulusReflex(application)

loadLegacyDefinitions()
loadConceptsDefinitions()
loadBorealDefinitions()
loadBusinessDefinitions()
loadTalentDefinitions()
loadStaffDefinitions()
loadAuraDefinitions()
loadAuroraHuntersDefinitions()

document.addEventListener('turbolinks:load', (event) => {
  restoreScrollTurbolinks()
  executeAnimation()
})

document.addEventListener('turbolinks:click', (event) => {
  setLinkAnimation(event)
})
