/* global Turbolinks */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["editSection", "field"]

  showProfileChanged(event) {
    event.target.checked ? this.showSection() : this.hideSection()
  }

  showSection() {
    this.editSectionTarget.classList.remove('hidden')
    this.fieldTargets.forEach((field) => { field.required = true })
  }

  hideSection() {
    this.editSectionTarget.classList.add('hidden')
    this.fieldTargets.forEach((field) => { field.required = false })
  }
}