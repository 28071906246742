import { Controller } from "stimulus";
import './range_field.scss'

export default class extends Controller {

  static targets = ["value", "progress"]
  static values = { unit: String }
  
  connect() {
  }

  updateValue(event) {
    this.valueTarget.innerHTML = event.target.value + this.unitValue
    this.progressTarget.style.width = event.target.value + '%'
  }
}
