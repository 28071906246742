/* global Turbolinks */
import { Controller } from 'stimulus';
import './timer.scss';

export default class extends Controller {

  static values = {
    endDate: String,
    hideClass: String,
    active: Boolean
  }
  static targets = [ 'timerSection', 'timer', 'expiredSection'  ]

  connect() {
    if(this.activeValue) this.initTimer()
  }

  initTimer() {
    this.timer = setInterval(this.getTime.bind(this), 60000);
  }

  getTime() {
    const endDate = new Date(this.endDateValue).getTime();
    const now = new Date().getTime();
    const diff = endDate - now;
    if (diff <= 0) {
      this.timerSectionTarget.classList.add(this.hideClassValue);
      this.expiredSectionTarget.classList.remove(this.hideClassValue);
      clearInterval(this.timer);
    }
    else {
      this.displayTime(diff);
    }
  }

  displayTime(diff) {
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    if( days > 0 ) {
      this.timerTarget.innerHTML = `${days} días`;
      return;
    }
    else if( hours > 0 ) {
      this.timerTarget.innerHTML = `${hours} horas`;
      return;
    }
    else {
      this.timerTarget.innerHTML = `${minutes} minutos`;
      return;
    }
  }
}