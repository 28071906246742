/* global Turbolinks */
import { Controller } from 'stimulus';
import './field_cloner.scss'

export default class extends Controller { 
  static targets = [ 'item', 'list' ]

  addOption() {
    const currentOptionPosition = this.itemTargets.length - 1
    const nextOptionPosition = currentOptionPosition + 1
    const newOption = this.getClonedNode(currentOptionPosition)
    const finalOption = this.editNewOption(newOption, currentOptionPosition, nextOptionPosition)
  
    this.listTarget.append(finalOption)
  }

  getClonedNode(currentOptionPosition) {
    const currentOption = this.itemTargets[currentOptionPosition]
    return currentOption.cloneNode(true)
  }

  editNewOption(element, currentOptionPosition, nextOptionPosition) {
    const inputs = element.querySelectorAll('input')
    const label = element.getElementsByTagName('label')[0];

    for (const input of inputs) {
      input.name = input.name.replace(currentOptionPosition.toString(), nextOptionPosition.toString())
      if (input.id) input.id = input.id.replace(currentOptionPosition.toString(), nextOptionPosition.toString())
      input.value = ""
    }
    label.htmlFor = label.htmlFor.replace(currentOptionPosition.toString(), nextOptionPosition.toString())

    return element
  }
}