/* global Turbolinks */
import { Controller } from 'stimulus';
import './footer.scss'
import { hear } from 'yelljs'

export default class extends Controller {
  
  static targets = [ 'title' ]
  
  connect() {
    for (const titleTarget of this.titleTargets) {
      const key = titleTarget.dataset.key
      hear(`accordion_${key}_before_open`, this.opened.bind(titleTarget))
      hear(`accordion_${key}_before_close`, this.closed.bind(titleTarget))
    }
  }
  
  opened() {
    this.querySelector('.talent-footer__icon').classList
      .replace('fa-chevron-down', 'fa-chevron-up')
  }
  
  closed() {
    this.querySelector('.talent-footer__icon').classList
      .replace('fa-chevron-up', 'fa-chevron-down')
  }
}
