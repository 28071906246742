import { Controller } from 'stimulus';
import 'css/app/recruiter/confirmation.scss';
import { hear } from 'yelljs';

export default class extends Controller {
  static targets = [ 'title' ]
  
  connect() {
    const key = this.titleTarget.dataset.key
    hear(`accordion_${key}_before_open`, this.opened.bind(this.titleTarget))
    hear(`accordion_${key}_before_close`, this.closed.bind(this.titleTarget))
  }
  
  opened() {
    this.querySelector('.recruiter-confirmations__arrow').classList
      .replace('fa-chevron-down', 'fa-chevron-up')
  }
  
  closed() {
    this.querySelector('.recruiter-confirmations__arrow').classList
      .replace('fa-chevron-up', 'fa-chevron-down')
  }
}
