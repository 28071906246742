/* global Turbolinks */
import { Controller } from 'stimulus';
import './video_button'

export default class extends Controller {

  static values = { videoaskLink: String, userId: String }

  connect() {
  }
  
  show(event) {
    event.stopPropagation()
    const customEvent = new CustomEvent('show_preview_video', { detail: { 
      url: this.videoaskLinkValue,
      userId: this.userIdValue
    } })
    document.dispatchEvent(customEvent)
  }
}