import { Controller } from "stimulus";
import './toggle.scss'

export default class extends Controller {
  static targets = [ 'checkbox', 'button', 'message' ]
  static values = { checkedMessage: String, uncheckedMessage: String, checkedButton: String, uncheckedButton: String }
  
  connect() {
    this.change(this.checkboxTarget.checked)
  }
  
  change(checked) {
    const message = checked ? this.checkedMessageValue : this.uncheckedMessageValue
    this.changeButton(checked)
    this.messageTarget.innerHTML = message
  }
  
  changeButton(checked) {
    const message = checked ? this.checkedButtonValue : this.uncheckedButtonValue
    this.buttonTarget.innerHTML = message
    this.buttonTarget.classList.toggle('toggle__button--secondary', checked)
  }
  
  toggle() {
    this.checkboxTarget.click()
    this.change(this.checkboxTarget.checked)
  }
}
