import { Controller } from "stimulus";
import Accordion from 'accordion-js'
import {yell} from "yelljs";

export default class extends Controller {
  connect() {
    const accordion = this.buildAccordion()
    if (this.element.dataset.open) accordion.open(Number(this.element.dataset.open))
  }
  
  buildAccordion() {
    return new Accordion(this.element, {
      showMultiple: true,
      onOpen: (currentElement) => {
        const key = currentElement.dataset.key
        yell(`accordion_${key}_open`, currentElement)
      },
      onClose: (currentElement) => {
        const key = currentElement.dataset.key
        yell(`accordion_${key}_close`, currentElement)
      },
      beforeOpen: (currentElement) => {
        const key = currentElement.dataset.key
        yell(`accordion_${key}_before_open`, currentElement)
      },
      beforeClose: (currentElement) => {
        const key = currentElement.dataset.key
        yell(`accordion_${key}_before_close`, currentElement)
      }
    })
  }
}