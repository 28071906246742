import ReflexController from '../../../frontend/controllers/reflex_controller'
import './top_navbar.scss'

export default class extends ReflexController {

  static targets = [ "popover" ]

  connect() {
    super.connect()
  }

  avatarClicked() {
    this.popoverTarget.classList.toggle('staff-top-navbar__popover--hide');
  }

  navigationButtonClicked() {
    this.popoverTarget.classList.add('staff-top-navbar__popover--hide');
    const event = new CustomEvent('navigationButtonClicked')
    document.dispatchEvent(event)
  }
}
