//TODO: This should be a stimulus component...

function executeAnimation() {
  const animation = sessionStorage.getItem("turbolinksAnimation")
  const animationTarget = sessionStorage.getItem("turbolinksAnimationTarget")
  const target = document.querySelector(animationTarget)
  if (animation && target) {
    target.classList.add(animation, "animate__animated")
    sessionStorage.removeItem("turbolinksAnimation")
    sessionStorage.removeItem("turbolinksAnimationTarget")
  }
}

function setLinkAnimation(event) {
  const linkTarget = event.target
  if (!linkTarget.classList.contains("turbolinks-animate")) return
  const animationName = linkTarget.dataset.turbolinksAnimation
  const animationTarget = linkTarget.dataset.turbolinksAnimationTarget
  setAnimation(animationName, animationTarget)
}

function setAnimation(animateName, animateTarget) {
  sessionStorage.setItem("turbolinksAnimation", animateName)
  sessionStorage.setItem("turbolinksAnimationTarget", animateTarget)
}

export { executeAnimation, setLinkAnimation, setAnimation }
