/* global Turbolinks */
import { Controller } from 'stimulus';
import { hear } from 'yelljs';

export default class extends Controller {
	static targets = [ 'select', 'display' ]

  connect() {
		hear('countrySelected', async (countryAlpha2) => {
			const cities = await this.fetchCities(countryAlpha2)
			this.enableSelect()
			this.clearSelect()
			this.loadCities(cities)
		})
  }

	async fetchCities (countryAlpha2) {
		const response = await fetch(`/country/${countryAlpha2}/cities`)
		const data = await response.json()
		return data.map(city => city.data.accentcity)
	}

	enableSelect () {
		this.element.classList.remove('Polaris-Select--disabled')
		this.selectTarget.disabled = false
	}

	clearSelect() {
		this.selectTarget.innerHTML = ''
		this.displayTarget.innerHTML = 'Selecciona una opción'
	}

	loadCities (cities) {
		for (const city of cities) {
			const option = document.createElement("option")
			option.text = city
			this.selectTarget.add(option)
		}
	}

	optionChanged(){
	}
}