import { Controller } from "stimulus";
import './contextual_save.scss'

export default class extends Controller {
  connect() {
    const formKey = this.element.dataset.formKey
    const formTarget = this.findForm(formKey)
    formTarget.addEventListener('input', () => {
      this.show()
    });
    formTarget.addEventListener('change', (e) => {
      if(e.target.matches('input, textarea, select')) this.show()
    });
  }
  
  show() {
    this.element.classList.add('contextual-save--active')
  }
  
  findForm(formKey) {
    // ViewComponents don't support content params from component to usage :(
    return document.querySelectorAll( `[data-form-key=${formKey}]`)[0]
  }
}
