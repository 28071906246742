import { Controller } from "stimulus";
import marked from 'marked'
import './rich_text.scss'

export default class extends Controller {

  connect(){
    const content = this.element.dataset.content
    if(content){
      this.element.innerHTML = marked(content)
      this.setLinksTarget()
    }
  }

  setLinksTarget(){
    const links = this.element.querySelectorAll('a')
    links.forEach((link) => {
      link.setAttribute('target', '_blank')
    })
  }
}
