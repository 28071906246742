import '@simonwep/pickr/dist/themes/monolith.min.css';
import Pickr from '@simonwep/pickr';
import { Controller } from 'stimulus';
import "./color_picker.scss"

export default class extends Controller {

  static targets = ['input', 'color'];

  static values = {
    color: String
  }

  connect() {
    const defaultColor = this.colorValue || '#000000';
    this.pickr = new Pickr({
      el: this.inputTarget,
      useAsButton: true,
      default: defaultColor,
      theme: 'monolith',
      swatches: [
        'rgba(244, 67, 54, 1)',
        'rgba(233, 30, 99, 1)',
        'rgba(156, 39, 176, 1)',
        'rgba(103, 58, 183, 1)',
        'rgba(63, 81, 181, 1)',
        'rgba(33, 150, 243, 1)',
        'rgba(3, 169, 244, 1)',
        'rgba(0, 188, 212, 1)',
        'rgba(0, 150, 136, 1)',
        'rgba(76, 175, 80, 1)',
        'rgba(139, 195, 74, 1)',
        'rgba(205, 220, 57, 1)',
        'rgba(255, 235, 59, 1)',
        'rgba(255, 193, 7, 1)'
      ],
      components: {
        preview: true,
        opacity: true,
        hue: true,
        interaction: {
          hex: true,
          rgba: true,
          input: true,
          clear: true,
          save: true
        }
      }
    }).on('init', pickr => {
      this.inputTarget.value = pickr.getSelectedColor().toHEXA().toString(0);
    }).on('save', color => {
      this.inputTarget.value = color.toHEXA().toString(0);
      this.colorTarget.style.backgroundColor = color.toHEXA().toString(0);
      this.pickr.hide();
    })
  }

  disconnect() {
    this.pickr.destroyAndRemove();
  }

  setPickrColor(event) {
    this.pickr.setColor(event.target.value);
  }
}