/* global Turbolinks */
import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { modalId: String }

  connect() {
    this.openModal();
  }

  openModal() {
    Promise.resolve().then(() => {
      const modalEvent = new CustomEvent('openBorealModal', { detail: { modalId: this.modalIdValue } })
      document.dispatchEvent(modalEvent)
    })    
  }
}