/* global Turbolinks */
import { Controller } from 'stimulus';

export default class extends Controller {

  typeformEmbedCode() {
    const baseUrl = this.data.get('url');
    const token = this.data.get('token');
    const redirectURI = this.data.get('redirect');
    const currentEmail = this.data.get('current-email');
    const url = `${baseUrl}?typeform-welcome=0&guest_token=${token}#email=${currentEmail}`;
    window.typeformEmbed.makeWidget(this.element, url, {
      hideFooter: true,
      hideHeaders: true,
      opacity: 100,
      onSubmit: () => {
        Turbolinks.visit(redirectURI);
      }
    });
  }

  connect() {
    this.typeformEmbedCode();
  }

  disconnect() {
    let currentScriptTag = document.getElementsByTagName('script')[0];
    currentScriptTag.parentNode.removeChild(currentScriptTag);
  }
}