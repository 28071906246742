/* global Turbolinks */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "sidebar" ]

  connect() {
    this.navigationButtonClicked = () => this.toggleSidebar()
    document.addEventListener('navigationButtonClicked', this.navigationButtonClicked)
  }

  disconnect() {
    document.removeEventListener('navigationButtonClicked', this.navigationButtonClicked, false)
  }

  toggleSidebar() {
    this.sidebarTarget.classList.toggle('staff-frame__sidebar--visible')
  }
}