/* global Turbolinks */
import { Controller } from 'stimulus';
import {hear} from "yelljs";

export default class extends Controller {
  static targets = [ 'select', 'display' ]
  connect() {
    this._updateDisplay()
    hear('countrySelected', async () => {
      this._updateDisplay()
    })
  }
  optionChanged() {
    this._updateDisplay()
  }
  _updateDisplay() {
    const select = this.selectTarget
    this.displayTarget.innerHTML = select.options[select.selectedIndex].text
  }
}
