/* global Turbolinks */
import ApplicationController from 'controllers/rails/application_controller.js';
import 'css/app/series.scss';

export default class extends ApplicationController {
  connect() {
    
  }
  shareModal(event) {
    const key = event.currentTarget.dataset.key
    MicroModal.show(`share-modal-${key}`);
  }

  navigate(event){
    const page = event.target.dataset.page
    const urlParams = new URLSearchParams(window.location.search);

    urlParams.set('page', page)
    Turbolinks.visit(`${window.location.pathname}?${urlParams.toString()}`)
  }
}