import { Controller } from "stimulus";
import "./avatar_field.scss"

export default class extends Controller {

  static targets = [ 'input', 'preview', "empty" ]
  
  connect() {
    super.connect()
  }
  
  openFileInput() {
    this.inputTarget.click()
  }
  
  fileUploaded() {
    const file = this.inputTarget.files[0]
    file ? this.setPreview(file) : this.hidePreview()
  }

  showPreview() {
    this.previewTarget.style.display = "block"
    this.emptyTarget.style.display = "none"
  }

  hidePreview() {
    this.previewTarget.style.display = "none"
    this.emptyTarget.style.display = "flex"
  }
  
  setPreview(file) {
    const reader = new FileReader()
    reader.addEventListener( "load", ()=> {
      this.previewTarget.setAttribute("src", reader.result);
    })
    reader.readAsDataURL(file);
    this.showPreview()
  }
}