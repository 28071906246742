import { Controller } from 'stimulus'
import Tagify from '@yaireo/tagify'
import '@yaireo/tagify/dist/tagify.css'
import './tag_input.scss'

export default class extends Controller {
  static targets = ['input']
  static values = { whitelist: Array }

  connect() {
    new Tagify(this.inputTarget, {
      whitelist: this.whitelistValue,
      maxTags: 10,
      dropdown: {
        maxItems: 20,
        enabled: 0,
        closeOnSelect: false
      }
    })
  }
}
