/* global Turbolinks */
import ApplicationController from 'controllers/rails/application_controller.js'
import 'css/app/business/pay_result.scss';
import Turbolinks from 'turbolinks';

export default class extends ApplicationController {

  static values = { path: String }

  connect() {
    setTimeout(() => this.redirect(), 3000);
  }

  redirect(){
    Turbolinks.visit(this.pathValue)
  }
}
