/* global Turbolinks */
import Turbolinks from 'turbolinks'
import ReflexController from '../../../frontend/controllers/reflex_controller'

export default class extends ReflexController {

  connect() {
    super.connect()
    this.showPreviewVideo = (event) => {
      this.url = event.detail.url
      const url = event.detail.url
      this.userId = event.detail.userId
      if (this.element.dataset.kind == "fullscreen") {
        window.videoask.loadModal( { url, options: { "modalType": "Fullscreen" } } )
      } else {
        window.videoask.loadModal({ url })
      }
    }
    document.addEventListener('show_preview_video', this.showPreviewVideo)
    window.addEventListener("message", message => this.onMessage(message))
  }

  disconnect() {
    document.removeEventListener('show_preview_video', this.showPreviewVideo, false)
  }
  
  onMessage(message) {
    if (!this.isVideoaskMessage(message)) return
    this.checkVideoaskMessage(message.data.type)
  }
  
  isVideoaskMessage(message) {
    return message.origin === "https://www.videoask.com" &&
      message.data &&
      message.data.type &&
      message.data.type.startsWith("videoask_")
  }
  
  checkVideoaskMessage(type) {
    if (type === 'videoask_submitted') return this.stimulate('Talent::PreviewVideoComponent#submit', this.url, this.userId)
  }
  
  afterReflex () {
    setTimeout(() => Turbolinks.visit('/my-profile'), 2500)
  }
}
