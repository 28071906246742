/* global Turbolinks */
import { Controller } from 'stimulus';
import MicroModal from 'micromodal';
import 'css/app/modal.scss';
import 'css/app/terms_modal.scss';

export default class extends Controller {

  connect() {

  }

  close() {
    MicroModal.close('terms-modal');
  }
}