/* global Turbolinks */
import { Controller } from 'stimulus';
import './header.scss'

export default class extends Controller {
    
  connect() {
  }

  openModal(event) {
    const modalEvent = new CustomEvent('openBorealModal', { detail: { modalId: event.target.dataset.modalId } })
    document.dispatchEvent(modalEvent)
  }
}
