/* global Turbolinks */
import { Controller } from 'stimulus';
import './video_gif'

export default class extends Controller {

  static targets = ['container', 'close']

  connect() {
    super.connect()
  }

  show() {
    this.containerTarget.ariaHidden = false
    this.containerTarget.classList.add('is-open')
  }

  close() {
    this.containerTarget.ariaHidden = true
    this.containerTarget.classList.remove('is-open')
  }
}
