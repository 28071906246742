import ReflexController from '../../../frontend/controllers/reflex_controller'
import Sortable from 'sortablejs';

export default class extends ReflexController {

  connect() {
    super.connect()
    this.sortable = Sortable.create(this.element, {
      onEnd: this.onEnd.bind(this)
    })
  }

  onEnd(event) {
    const itemId = event.item.dataset.id
    this.stimulate('Boreal::SortableListComponent#update_position', {id: itemId, position: event.newIndex + 1})
  }
}
