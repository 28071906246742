import { Controller } from "stimulus";
import './simple_clipboard.scss'

export default class extends Controller {
  static targets = ["tooltip"]
  static values = { clipboard: String, visibleClass: String }

  copyToClipboard() {
    navigator.clipboard.writeText(this.clipboardValue).then(this.showTooltip())
  }

  showTooltip() {
    this.tooltipTarget.classList.add(this.visibleClassValue)
    setTimeout(() => {
      this.tooltipTarget.classList.remove(this.visibleClassValue)
    }, 1000);
  }
}
