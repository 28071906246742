/* global Turbolinks */
import ReflexController from '../../../frontend/controllers/reflex_controller'

export default class extends ReflexController {

  connect() {
    super.connect()
    this.showPreviewVideo = (event) => {
      this.loadVideo(event.detail.url)
    }
    document.addEventListener('business_show_preview_video', this.showPreviewVideo)
  }

  loadVideo(url) {
    if (this.element.dataset.kind == "fullscreen") {
      window.videoask.loadModal( { url, options: { "modalType": "Fullscreen" } } )
    } else {
      window.videoask.loadModal({ url })
    }
  }

  disconnect() {
    document.removeEventListener('business_show_preview_video', this.showPreviewVideo, false)
  }
}
