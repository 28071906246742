/* global Turbolinks */
import { Controller } from 'stimulus';
import Swiper, { Navigation, EffectFade } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css';
import './job_published_modal.scss';

export default class extends Controller { 
  connect() {
    this.initParams()
    this.swiper = new Swiper(this.element, this.swiperParams )
  }

  initParams(){
    this.swiperParams = {
      modules: [ Navigation, EffectFade ],
      init: true,
      speed: 600,
      slideClass: 'business-job-published-modal__slide',
      allowTouchMove: false,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
    }
  }

  next(){
    this.swiper.slideNext()
  }
}