export default function() {
  //TODO: This should be a stimulus component...
  const elements = document.querySelectorAll("[data-turbolinks-scroll]")
  elements.forEach(function(element) {
    element.addEventListener("click", ()=> {
      sessionStorage.setItem('scroll', document.scrollingElement.scrollTop)
    });
    element.addEventListener("submit", ()=> {
      sessionStorage.setItem('scroll', document.scrollingElement.scrollTop)  
    });
  });

  const scroll = sessionStorage.getItem('scroll')
  if (scroll) {
    document.scrollingElement.scrollTo(0, scroll)
  }
}