/* global Turbolinks */
import { Controller } from 'stimulus';
import './series.scss'

export default class extends Controller {
  
  connect() {
  }
}

