import { Controller } from 'stimulus';
import Accordion from 'accordion-js'
import './search.scss'

export default class extends Controller {

  static targets = [ "accordion", "step", "stepTitle", "stepLabel", "stepLabelSelected", "skipButton", "restoreButton" ]
  static values = { modalId: String, bottomOffset: Number }

  connect() {
    this.openSearchModal = (event) => {
      if (event.detail && this.modalIdValue == event.detail.modalId) {
        this.initSteps()
        this.accordion = this.buildAccordion()
        this.accordion.open(event.detail.stepIndex)    
      }
    }
    document.addEventListener('openSearchModal', this.openSearchModal)

    this.borealModalClosed = (event) => {
      if (event.detail.modalId !== this.modalIdValue) return
      this.accordion.destroy()
    }
    document.addEventListener('borealModalClosed', this.borealModalClosed)
  }

  disconnect() {
    document.removeEventListener('openSearchModal', this.openSearchModal, false)
    document.removeEventListener('borealModalClosed', this.borealModalClosed, false)
  }

  initSteps() {
    this.stepTargets.forEach((step) => {
      const stepId = step.dataset.stepId
      const inputsChecked = step.querySelectorAll('input:checked')
      const contents = [...inputsChecked].map((input) => input.dataset.content).join(", ")
      this.updateLabel(contents, stepId)
      this.toggleStepFooter(stepId, inputsChecked.length > 0)
    })
  }
  
  buildAccordion() {
    return new Accordion(this.accordionTarget, {
      showMultiple: false,
      onOpen: (currentElement) => this.scrollToStep(currentElement)
    })
  }

  scrollToStep(currentElement) {
    const top = currentElement.offsetTop - this.bottomOffsetValue
    this.element.scroll({
      top: top,
      behavior: "smooth",
    }); 
  }

  next(event) {
    const stepId = event.target.dataset.stepId
    const step = this.stepTargets.find((step) => step.dataset.stepId === stepId)
    this.nextStep(this.stepTargets.indexOf(step))
  }

  nextStep(currentOpenIndex) {
    if(this.stepTargets[currentOpenIndex + 1]) {
      this.accordion.close(currentOpenIndex)
      this.accordion.open(currentOpenIndex + 1)
    }
  }

  inputChanged(event) {
    const stepId = event.target.dataset.stepId
    const step = this.stepTargets.find((step) => step.dataset.stepId === stepId)
    const inputsChecked = step.querySelectorAll('input:checked')
    const contents = [...inputsChecked].map((input) => input.dataset.content).join(", ")
    this.updateLabel(contents, stepId)
    if(inputsChecked[0].type == "radio") this.nextStep(this.stepTargets.indexOf(step)) 
    this.toggleStepFooter(stepId, inputsChecked.length > 0)
  }

  reset() {
    this.stepTargets.forEach((step) => {
      this.resetStep({ target: step })
    })
  }

  resetStep(event) {
    const stepId = event.target.dataset.stepId
    const step = this.stepTargets.find((step) => step.dataset.stepId === stepId)
    const inputs = step.querySelectorAll('input')
    inputs.forEach((input) => input.checked = false)
    this.updateLabel('', stepId)
    this.toggleStepFooter(stepId, false)
  }

  updateLabel(content, stepId) {
    const label = this.stepLabelSelectedTargets.find((label) => label.dataset.stepId === stepId)
    label.innerHTML = content
  }

  toggleStepFooter(stepId, show) {
    const skipButton = this.skipButtonTargets.find((button) => button.dataset.stepId === stepId)
    const restoreButton = this.restoreButtonTargets.find((button) => button.dataset.stepId === stepId)
    if (!skipButton || !restoreButton) return
    if(show) {
      skipButton.classList.add('aura-search__step-button--hidden')
      restoreButton.classList.remove('aura-search__step-button--hidden')
    } else {
      skipButton.classList.remove('aura-search__step-button--hidden')
      restoreButton.classList.add('aura-search__step-button--hidden')
    }
  }
}