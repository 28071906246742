/* global Turbolinks */
import { Controller } from 'stimulus';
import { hear } from "yelljs";

export default class extends Controller {
  connect() {
    hear('polarisFrameTopbarMenuClicked', () => {
      this.element.classList.toggle('hidden');
    });
  }
}