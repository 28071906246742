/* global Turbolinks */
import { Controller } from 'stimulus';
import './clipboard.scss';

export default class extends Controller {

  static targets = [ "content", "button" ]
  static values = { success: String, default: String, successClass: String }

  copy() {
    navigator.clipboard.writeText(this.contentTarget.value).then(this.animate())
  }

  animate() {
    this.buttonTarget.innerHTML = this.successValue
    this.element.classList.add(this.successClassValue)
    setTimeout(() => {
      this.buttonTarget.innerHTML = this.defaultValue
      this.element.classList.remove(this.successClassValue)
    }, 1000);
  }
}