import ReflexController from '../../../../frontend/controllers/reflex_controller'
import { setAnimation } from '../../../../frontend/config/turbolinks_animation'
import Pristine from 'pristinejs';
import "./wizard.scss"

export default class extends ReflexController {

  SELECT_INPUT_TYPES = ["select-one", "select-multiple"]

  connect() {
    super.connect()
  }

  submit(event) {
    return this.validate() ? this.goToNextStep() : this.preventSubmit(event)
  }

  goToNextStep() {
    setAnimation("animate__fadeInRight", ".aura-forms-step__main")
  }

  preventSubmit(event) {
    event.preventDefault()
    this.stimulate("Aura::Forms::StepReflex#show_error")
  }

  validate() {
    const pristine = new Pristine(this.element)
    return pristine.validate()
  }
}

