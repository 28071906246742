import { Controller } from 'stimulus';
import './easy_apply.scss';

export default class extends Controller {

  static values = {
    activeClass: String
  }

  connect() {
    this.setUrlParams()
    const filter = this.urlParams.get('easy_apply')
    this.element.classList.toggle(this.activeClassValue, !!filter)
  }

  setUrlParams() {
    const queryString = window.location.search;
    this.urlParams = new URLSearchParams(queryString);
  }

  filter() {
    const filter = this.urlParams.get('easy_apply')
    filter ? this.urlParams.delete('easy_apply') : this.urlParams.set('easy_apply', 'aurora')
    
    this.urlParams.delete('page')
    Turbolinks.visit(`/jobs?${this.urlParams.toString()}`)
  }
}