/* global Turbolinks */
import { Controller } from 'stimulus';
import { yell } from 'yelljs'

export default class extends Controller {
	static targets = [ 'select' ]

  connect() {
    // Waiting for controllers to be ready to yell default country
    // https://github.com/hotwired/stimulus/issues/201#issuecomment-435285227
    Promise.resolve().then(() => {
      if(!this.selectTarget.value) {
        this.selectTarget.value = 'ES'
        yell('countrySelected', 'ES')
      }
    })
  }

	async optionChanged() {
		yell('countrySelected', this.getSelectedValue())
	}

	getSelectedValue () {
		const select = this.selectTarget
    return select.options[select.selectedIndex].value
	}
}
