/* global Turbolinks */
import { Controller } from 'stimulus';
import Accordion from 'accordion-js'
import './application.scss';

export default class extends Controller {
  
  static targets = [ "accordion", "icon" ]
  static values = { applicantId: String, rotateClass: String }

  connect() {
    this.accordion = this.buildAccordion()
  }
  
  buildAccordion() {
    return new Accordion(this.accordionTarget, {
      showMultiple: true,
      beforeOpen: () => this.toggleIcon(),
      beforeClose: () => this.toggleIcon()
    })
  }

  toggleIcon() {
    this.iconTarget.classList.toggle(this.rotateClassValue)
  }
}
