/* global Turbolinks */
import { Controller } from 'stimulus';
import {yell} from "yelljs";

export default class extends Controller {
  connect() {
    this.element.style.zIndex = 49;
  }
  menuClicked() {
    yell('polarisFrameTopbarMenuClicked');
  }
}