/* global Turbolinks */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['imageSection']

  toggleImageSection(event) {
    const kind = event.target.value
    this.imageSectionTarget.classList.toggle('hidden', kind !== 'image')
  }
}