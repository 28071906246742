import ReflexController from '../../../frontend/controllers/reflex_controller'
import './preview_group.scss'

export default class extends ReflexController {

  static targets = [ "filleableType", "userEmail", "companyName", "jobTitle", "selectField" ]

  connect() {
    super.connect()
  }

  typeChaged() {
    this.stimulate("Staff::PreviewGroupComponent#type_changed", this.filleableTypeTarget.value)
  }

  selectFilleable() {
    this.selectFieldTarget.remove()
    const value = this.getIdentifierValue()
    this.stimulate("Staff::PreviewGroupComponent#select_filleable", value)
  }

  removeFilleable() {
    if (this.hasSelectFieldTarget) this.selectFieldTarget.remove()
    this.stimulate("Staff::PreviewGroupComponent#remove_filleable")
  }

  getIdentifierValue() {
    if (this.hasUserEmailTarget) {
      return this.userEmailTarget.value
    } else if (this.hasCompanyNameTarget) {
      return this.companyNameTarget.value
    } else if (this.hasJobTitleTarget) {
      return this.jobTitleTarget.value
    }
  }
}
