/* global Turbolinks */
import ReflexController from '../../../frontend/controllers/reflex_controller'
import "../../css/admin/catalogs/item.scss"

export default class extends ReflexController {

  static targets = [ 'item', 'list' ]

  connect() {
    super.connect()
  }
  
  addItem() {
    const currentItemPosition = this.itemTargets.length - 1
    const nextItemPosition = currentItemPosition + 1
    const newItem = this.getClonedNode(currentItemPosition)
    const finalItem = this.editNewItem(newItem, currentItemPosition, nextItemPosition)
  
    this.listTarget.append(finalItem)
  }

  getClonedNode(currentItemPosition) {
    const currentItem = this.itemTargets[currentItemPosition]
    return currentItem.cloneNode(true)
  }

  editNewItem(element, currentItemPosition, nextItemPosition) {
    const inputs = element.querySelectorAll('input')
    const label = element.getElementsByTagName('label')[0];

    for (const input of inputs) {
      input.name = input.name.replace(currentItemPosition.toString(), nextItemPosition.toString())
      if (input.id) input.id = input.id.replace(currentItemPosition.toString(), nextItemPosition.toString())
    }
    label.htmlFor = label.htmlFor.replace(currentItemPosition.toString(), nextItemPosition.toString())

    return element
  }
}