/* global Turbolinks */
import { Controller } from 'stimulus'
import Swiper, { EffectFade } from 'swiper'
import 'css/app/talent/apply_form.scss'
import 'swiper/swiper.scss'
import 'swiper/swiper-bundle.css'

export default class extends Controller {
  static targets = [
    'slider',
    'alert',
    'alertList',
    'phone',
    'resume',
    'avatar',
    'whyMe',
    'killerAnswer',
    'bio',
    'buttonApply',
    'form'
  ]
  static values = { applyingClass: String, profileComplete: Boolean }

  connect() {
    this.initParams()
    this.swiper = new Swiper(this.sliderTarget, this.swiperParams)
  }

  disconnect() {
    this.swiper.destroy()
  }

  initParams() {
    this.swiperParams = {
      modules: [EffectFade],
      init: true,
      speed: 600,
      slideClass: 'talent-apply-form__container',
      allowTouchMove: false,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      }
    }
  }

  next() {
    this.swiper.slideNext()
  }

  prev() {
    this.swiper.slidePrev()
  }

  apply() {
    if (this.profileFilled() && this.aboutFilled()) return this.submitForm()
    return this.showAlert()
  }

  submitForm() {
    this.disableButton()
    this.formTarget.submit()
  }

  profileFilled() {
    return (
      this.isPhoneValid() &&
      this.isResumeValid() &&
      this.isAvatarValid() &&
      this.isBioValid()
    )
  }

  aboutFilled() {
    return this.isWhyMeValid() && this.isKillerAnswerValid()
  }

  showAlert() {
    this.buildMessage()
    this.alertTarget.classList.add('talent-apply-form__alert--show')
    setTimeout(() => {
      this.closeAlert()
    }, 3000)
  }

  closeAlert() {
    this.alertTarget.classList.remove('talent-apply-form__alert--show')
  }

  isPhoneValid() {
    return this.phoneTarget.value.trim().length > 0
  }

  isResumeValid() {
    return (
      this.resumeTarget.dataset.filled == 'true' ||
      this.resumeTarget.files.length > 0
    )
  }

  isAvatarValid() {
    return (
      this.avatarTarget.dataset.filled == 'true' ||
      this.avatarTarget.files.length > 0
    )
  }

  isWhyMeValid() {
    if (!this.hasWhyMeTarget) return true
    const areaLength = this.whyMeTarget.value.trim().length
    const limit = Number(this.whyMeTarget.dataset.limit) || 500
    return areaLength <= limit && areaLength > 0
  }

  isKillerAnswerValid() {
    if (!this.hasKillerAnswerTarget) return true
    const areaLength = this.killerAnswerTarget.value.trim().length
    const limit = Number(this.killerAnswerTarget.dataset.limit) || 500
    return areaLength <= limit && areaLength > 0
  }

  isBioValid() {
    if (!this.hasBioTarget) return true
    const areaLength = this.bioTarget.value.trim().length
    const limit = Number(this.bioTarget.dataset.limit) || 500
    return areaLength <= limit && areaLength > 0
  }

  buildMessage() {
    this.alertListTarget.innerHTML = ''
    const unFilledFields = []
    if (!this.isPhoneValid()) unFilledFields.push(this.phoneTarget)
    if (!this.isResumeValid()) unFilledFields.push(this.resumeTarget)
    if (!this.isAvatarValid()) unFilledFields.push(this.avatarTarget)
    if (!this.isWhyMeValid()) unFilledFields.push(this.whyMeTarget)
    if (!this.isKillerAnswerValid())
      unFilledFields.push(this.killerAnswerTarget)
    if (!this.isBioValid()) unFilledFields.push(this.bioTarget)
    for (let i = 0; i < unFilledFields.length; i++) {
      const li = document.createElement('li')
      li.innerText = unFilledFields[i].dataset.alert
      this.alertListTarget.appendChild(li)
    }
  }

  disableButton() {
    this.buttonApplyTarget.classList.add(this.applyingClassValue)
    this.buttonApplyTarget.disabled = true
  }
}
