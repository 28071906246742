/* global Turbolinks */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'preview', 'input' ]

  connect() {
    this.updatePreview(this.inputTarget.value)
  }

  changed(event) {
    this.updatePreview(event.currentTarget.value)
  }

  updatePreview(color) {
    this.previewTarget.innerHTML = color
    this.previewTarget.style.color = color
  } 
}