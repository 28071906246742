function componentDefinitionsFromContext(contextComponents, componentType) {
  return contextComponents.keys().map((path) => {
    return createStimulusContext(contextComponents, path, componentType)
  })
}

function createStimulusContext(contextComponents, path, type) {
  const splitPath = path.split('/')
  const subfolderPath = splitPath.slice(1, splitPath.length - 1)
  const stimulusControllerName = `${type}--${subfolderPath.join("--").replace("_component", "").replace(/_/g, '-')}`
  const obj = contextComponents(path)
  
  const key = Object.keys(obj)[0]
  const controllerConstructor = obj[key]
  
  return { identifier: stimulusControllerName, controllerConstructor }
}

export { componentDefinitionsFromContext }

