import { componentDefinitionsFromContext } from '../webpack_component_helper'

export default function () {
  const talentComponentContext = require.context("../../../components/talent", true, /_controller\.js$/)
  const talentComponentDefinitions = componentDefinitionsFromContext(talentComponentContext, 'talent')

  const unauthenticatedComponentContext = require.context("../../../components/unauthenticated", true, /_controller\.js$/)
  const unauthenticatedComponentDefinitions = componentDefinitionsFromContext(unauthenticatedComponentContext, 'unauthenticated')

  const definitions = talentComponentDefinitions.concat(unauthenticatedComponentDefinitions)

  window.application.load(definitions)
}
