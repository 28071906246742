import { Controller } from 'stimulus';
import 'css/app/scroll_top.scss';

export default class extends Controller {

  static targets = [ "container", "scrollTop" ]

  top() {
    this.containerTarget.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  scrollDown(event) {
    if (!this.hasScrollTopTarget) return false
    if (event.target.scrollTop > 250) this.scrollTopTarget.classList.add('scroll-top__active')
    if (event.target.scrollTop < 250) this.scrollTopTarget.classList.remove('scroll-top__active')
  }
}
