import { Controller } from "stimulus";
import './button.scss'

export default class extends Controller {
  changeStateToLoading() {
    this.element.style.width = `${this.element.offsetWidth}px`;
    this.element.style.height = `${this.element.offsetHeight}px`;
    this.element.classList.add('cursor-not-allowed');
    this.element.innerHTML = '<i class="fa-sharp animate-spin fa-spinner-third"></i>';
    setTimeout(() => this.element.disabled = true, 0)
  }
}
