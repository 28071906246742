import { Controller } from "stimulus";
import { createWidget } from '@typeform/embed'
import '@typeform/embed/build/css/widget.css'
import './typeform.scss'

export default class extends Controller {

  static values = { formId: String, fullName: String, email: String }
  
  connect() {
    createWidget(this.formIdValue, { 
      container: this.element,
      hidden: {
        full_name: this.fullNameValue,
        email: this.emailValue
      }
    })
  }
}