import { Controller } from "stimulus";
import './password_field.scss';

export default class extends Controller {
  static targets = [ "input" ]

  setToggle() {
    const isVisible = this.element.dataset.visible === 'true'
    return this.element.dataset.visible = !isVisible
  }

  toggleVisibility(event) {
    const isVisible = this.setToggle()
    if (isVisible) {
      event.target.src = this.element.dataset.iconHidden
      this.inputTarget.type = 'text'
    } else {
      event.target.src = this.element.dataset.iconVisible
      this.inputTarget.type = 'password'
    }
  }
}
