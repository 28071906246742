/* global Turbolinks */
import ApplicationController from 'controllers/rails/application_controller.js'
import 'css/app/business/applicants_layout.scss';

export default class extends ApplicationController {
  static targets = [ 'sidebar', 'container' ]
  BUSINESS_STORAGE_POSITION = 'scroll-position-y'

  connect(){
    this.restoreScrollPosition()
    this.bindTurbolinkEvents()
  }

  bindTurbolinkEvents(){
    const resetScrollElements = document.querySelectorAll('[data-turbolinks-scroll="false"]')
    resetScrollElements.forEach(element => {
      element.addEventListener('click', this.resetScrollPosition.bind(this))
      element.addEventListener('submit', this.resetScrollPosition.bind(this))
    })
    const persistScrollElements = document.querySelectorAll("[data-turbolinks-persist-scroll]")
    persistScrollElements.forEach(element => {
      element.addEventListener('click', this.saveScrollPosition.bind(this))
      element.addEventListener('submit', this.saveScrollPosition.bind(this))
    })
  }

  restoreScrollPosition(){
    const y = sessionStorage.getItem(this.BUSINESS_STORAGE_POSITION) || 0
    this.containerTarget.scrollTo({ top: y, behavior: 'auto' });
  }

  saveScrollPosition(){
    window.sessionStorage.setItem(this.BUSINESS_STORAGE_POSITION, this.containerTarget.scrollTop)
  }

  resetScrollPosition(){
    window.sessionStorage.setItem(this.BUSINESS_STORAGE_POSITION, 0)
  }

  toggleSidebar(){
    this.sidebarTarget.classList.toggle("business-applicants-layout__sidebar--opened")
    this.sidebarTarget.classList.toggle("business-applicants-layout__sidebar--closed")
    this.containerTarget.classList.toggle("business-applicants-layout__main--expanded")
    this.containerTarget.classList.toggle("business-applicants-layout__main--minimized")
  }

  showModal(event){
    const modalEvent = new CustomEvent('openBorealModal', { detail: { modalId: event.target.dataset.modalId } })
    document.dispatchEvent(modalEvent)
  }
}
