import ApplicationController from 'controllers/rails/application_controller.js';
import 'css/app/jobs.scss';
import 'css/app/job.scss';
import 'css/app/talent/apply_modal.scss';

export default class extends ApplicationController {

  static targets = ['applySection', 'bottomBar', 'topBar', 'externalForm']
  STORAGE_POSITION = 'scroll-position-y'
  STORAGE_SEARCH = 'window-location-search'

  connect() {
    if (!this.isUserListingJobs()) return
    if (this.isUserReturning()) this.scrollRestore()
    this.saveIndexPathSearch()
  }

  saveIndexPathSearch() {
    window.sessionStorage.setItem(this.STORAGE_SEARCH, window.location.search)
  }

  isUserListingJobs() {
    return this.element.dataset.controllerAction === 'index'
  }

  isUserReturning() {
    const lastSearch = sessionStorage.getItem(this.STORAGE_SEARCH) || ""
    return lastSearch === window.location.search
  }

  scrollRestore(){
    const y = sessionStorage.getItem(this.STORAGE_POSITION) || 0
    this.containerTarget.scrollTo({ top: y, behavior: 'auto' });
  }

  scrollDown(){
    if (!this.isUserListingJobs()) return
    window.sessionStorage.setItem(this.STORAGE_POSITION, this.containerTarget.scrollTop)
  }

  scrollJob(){
    if (this.isUserListingJobs()) return
    const isInViewport = this.applySectionIsInViewport()
    this.bottomBarTarget.classList.toggle('job__bottom-bar--hidden', isInViewport)
    this.topBarTarget.classList.toggle('job__nav--hidden', isInViewport)
  }

  applySectionIsInViewport() {
    const rect = this.applySectionTarget.getBoundingClientRect();
    return (
      rect.top <= 20 &&
      rect.left >= 0 &&
      (rect.bottom <= (window.innerHeight + 150 || document.documentElement.clientHeight + 150) || (rect.bottom <= rect.height)) &&
      rect.right <= (window.innerWidth + 150 || document.documentElement.clientWidth + 150)
    )
  }

  scrollToApply() {
    this.applySectionTarget.scrollIntoView({ behavior: 'smooth' });
  }

  externalApply() {
    window.open(this.externalFormTarget.dataset.path, '_blank')
    this.externalFormTarget.submit()
  }
}