/* global Turbolinks */
import { Controller } from 'stimulus';
import './file_uploader.scss'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Spanish from '@uppy/locales/lib/es_ES';

export default class extends Controller {

  static targets = [ 'button', 'input', 'preview', 'container' ]

  connect() {
    this.dataTransfer = new DataTransfer()
    const maxNumberOfFiles = Number(this.element.dataset.maxNumberOfFiles)
    this.uppy = new Uppy({
      autoProceed: false,
      logger: Uppy.debugLogger,
      restrictions: {
        maxFileSize: null,
        maxNumberOfFiles: maxNumberOfFiles,
        allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.gif'],
      },
    })

    this.uppy.on('files-added', (uppyFiles, reason) => {
      const files = uppyFiles.map(result => result.data)
      this.addUploadedFiles(files)
    })
    .on('file-removed', (uppyFile, reason) => {
      this.removeFromInput(uppyFile.data)
    })
    .use(Dashboard, {
      trigger: this.buttonTarget,
      inline: true,
      target: this.containerTarget,
      width: '100%',
      height: '450px',
      locale: Spanish,
      proudlyDisplayPoweredByUppy: false,
    })
  }

  disconnect() {
    this.uppy.close()
  }
  
  addUploadedFiles(files) {
    files.forEach(file => this.appendToInput(file))
  }
  
  appendToInput(file) {
    this.dataTransfer.items.add(this.createDataTransferFile(file))
    this.inputTarget.files = this.dataTransfer.files
  }
  
  removeFromInput(file) {
    const dataTransferList = Object.values(this.dataTransfer.items)
    const fileNames = dataTransferList.map(dataTransfer => dataTransfer.getAsFile().name)
    const targetIndex = fileNames.indexOf(file.name)
    this.dataTransfer.items.remove(targetIndex)
    this.inputTarget.files = this.dataTransfer.files
  }

  createDataTransferFile(file) {
    return new File([file.slice(0, file.size, file.type)], file.name)
  }
}