/* global Turbolinks */
import ReflexController from '../../../frontend/controllers/reflex_controller'
import './video_iframe'

export default class extends ReflexController {

  connect() {
    super.connect()
    window.addEventListener("message", message => this.onMessage(message))
  }
  
  onMessage(message) {
    if (!this.isVideoaskMessage(message)) { return }
    this.checkVideoaskMessage(message.data.type)
  }
  
  isVideoaskMessage(message) {
    return message.origin === "https://www.videoask.com" &&
      message.data &&
      message.data.type &&
      message.data.type.startsWith("videoask_")
  }
  
  checkVideoaskMessage(type) {
    if (type === 'videoask_submitted') return this.stimulate('Talent::VideoIframeComponent#submit')
  }
  
  afterReflex () {
    const callbackUrl = this.element.dataset.callbackUrl
    if (callbackUrl) return Turbolinks.visit(callbackUrl)
  }
}