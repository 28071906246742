import { Controller } from 'stimulus';
import Accordion from 'accordion-js'
import './filter.scss'
import Turbolinks from 'turbolinks';

export default class extends Controller {

  static targets = [ "accordion", "step", "icon" ]
  static values = { rotateClass: String }

  connect() {
    this.accordion = this.buildAccordion()
  }
  
  buildAccordion() {
    return new Accordion(this.accordionTarget, {
      showMultiple: true,
      beforeOpen: (currentElement) => this.toggleIcon(currentElement),
      beforeClose: (currentElement) => this.toggleIcon(currentElement)
    })
  }

  toggleIcon(currentElement) {
    const icon = this.iconTargets.find((icon) => icon.dataset.stepId === currentElement.dataset.stepId)
    icon.classList.toggle(this.rotateClassValue)
  }

  next(event) {
    const stepId = event.target.dataset.stepId
    const step = this.stepTargets.find((step) => step.dataset.stepId === stepId)
    this.nextStep(this.stepTargets.indexOf(step))
  }

  nextStep(currentOpenIndex) {
    if(this.stepTargets[currentOpenIndex + 1]) {
      this.accordion.close(currentOpenIndex)
      this.accordion.open(currentOpenIndex + 1)
    }
  }

  reset() {
    Turbolinks.visit(window.location.pathname)
  }

  resetStep(event) {
    const stepId = event.target.dataset.stepId
    const step = this.stepTargets.find((step) => step.dataset.stepId === stepId)
    const inputs = step.querySelectorAll('input')
    inputs.forEach((input) => input.checked = false)
  }
}