import ReflexController from '../../../frontend/controllers/reflex_controller'
import { hear } from 'yelljs'
import './product.scss'

export default class extends ReflexController {

  static targets = [ 'description' ]
  connect() {
    for (const descriptionTarget of this.descriptionTargets) {
      const key = descriptionTarget.dataset.key
      hear(`accordion_${key}_before_open`, this.opened.bind(descriptionTarget))
      hear(`accordion_${key}_before_close`, this.closed.bind(descriptionTarget))
    }
  }
  
  opened() {
    this.querySelector('.business-product__icon').classList
      .replace('fa-chevron-down', 'fa-chevron-up')
  }
  
  closed() {
    this.querySelector('.business-product__icon').classList
      .replace('fa-chevron-up', 'fa-chevron-down')
  }

  buttonMouseOver() {
    this.element.classList.add("hover")
  }

  buttonMouseOut() {
    this.element.classList.remove("hover")
  }
}
