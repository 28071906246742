/* global Turbolinks */
import ApplicationController from 'controllers/rails/application_controller.js'
import 'css/app/business/core.scss';

export default class extends ApplicationController {
  static targets = [ 'container' ]
  connect() {
    this.containerTarget.addEventListener('scroll', (event) => {
      const customEvent = new CustomEvent('businessContainerScrolled', { detail: {target: event.target} })
      document.dispatchEvent(customEvent)
    })
  }
}
