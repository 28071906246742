/* global Turbolinks */
import { Controller } from 'stimulus';
import './members/store.scss'

export default class extends Controller {
  static targets = ["superadmin", "role"]

  connect() {
  }

  checkAll() {
    if (!this.superadminTarget.checked) return 
    this.roleTargets.forEach(role => role.checked = true)
  }

  toggleSuperadmin() {
    const uncheckedRole = this.roleTargets.find(role => role.checked == false)
    this.superadminTarget.checked = !uncheckedRole
  }
}