/* global Turbolinks */
import { Controller } from 'stimulus';
import './contents/store.scss'

export default class extends Controller {
  static values = { hiddenClass: String }
  static targets = [ "banner", "blog", "job", "kindSelect", "themeSelect" ]

  connect() {
    this.changeKind()
    this.changeTheme()
  }

  changeKind() {
    const kind = this.kindSelectTarget.value
    this.blogTarget.classList.toggle(this.hiddenClassValue, kind !== 'blog')
    this.jobTarget.classList.toggle(this.hiddenClassValue, kind !== 'with_item')
  }

  changeTheme() {
    const theme = this.themeSelectTarget.value
    this.bannerTarget.classList.toggle(this.hiddenClassValue, theme !== 'banner')
  }
}