import { Controller } from 'stimulus';
import './order.scss'

export default class extends Controller {

  static targets = [ 'options', 'blurContainer', 'button' ]

  connect() {
    this.setUrlParams()
  }

  setUrlParams() {
    const queryString = window.location.search;
    this.urlParams = new URLSearchParams(queryString);
  }

  showOptions(){
    this.optionsTarget.style.display = 'block'
    this.blurContainerTarget.style.display = 'block'
    this.buttonTarget.classList.add('active')
  }

  hideOptions(){
    this.optionsTarget.style.display = 'none'
    this.blurContainerTarget.style.display = 'none'
    this.buttonTarget.classList.remove('active')
  }

  optionChanged(event) {
    this.urlParams.set('order', event.target.value)
    this.urlParams.delete('page')
    Turbolinks.visit(`${window.location.pathname}?${this.urlParams.toString()}`)
  }
}