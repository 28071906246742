/* global Turbolinks */
import { Controller } from 'stimulus';
import './field.scss'

export default class extends Controller {
  
  static targets = [ 'title' ]
  
  connect() {}
}
