/* global Turbolinks */
import { Controller } from 'stimulus';
import './filter_options.scss';

export default class extends Controller {
  static values = {
    selector: String,
    content: String
  }

  filter(event) {
    const filterOptions = this.element.getElementsByClassName(this.selectorValue)
    const filterValue = event.target.value.toUpperCase()
    for (let i = 0; i < filterOptions.length; i++) {
      const label = filterOptions[i].getElementsByClassName(this.contentValue)[0]
      const labelContent = label.textContent || label.innerText;
      if (labelContent.toUpperCase().indexOf(filterValue) > -1) {
        filterOptions[i].style = ''
      } else {
        filterOptions[i].style = 'display: none;'
      }
    }
  }
}