import { Controller } from 'stimulus'
import Swiper from 'swiper'
import './body.scss'
import 'swiper/swiper.scss'
import 'swiper/swiper-bundle.css'

export default class extends Controller {
  static targets = ['slide', 'button']

  connect() {
    this.initParams()
    this.swiper = new Swiper(this.element, this.swiperParams)
    this.checkButtons()
    this.swiper.on('slideChange', () => this.checkButtons())
  }

  initParams() {
    this.swiperParams = {
      init: true,
      speed: 600,
      direction: 'vertical',
      allowTouchMove: false
    }
  }

  slideToVideo(event) {
    const itemId = event.target.dataset.video
    const videoSlide = this.slideTargets.find(
      (slide) => slide.dataset.slide === itemId
    )
    const index = this.slideTargets.indexOf(videoSlide)
    this.swiper.slideTo(index)
  }

  slideToApplicantCard() {
    const applicantCardSlide = this.slideTargets.find(
      (slide) => slide.dataset.slide === 'applicantCard'
    )
    const index = this.slideTargets.indexOf(applicantCardSlide)
    this.swiper.slideTo(index)
  }

  checkButtons() {
    this.buttonTargets.forEach((button) =>
      button.classList.remove(
        'business-applicant-body__pagination-button--active'
      )
    )
    const button = this.buttonTargets.find(
      (button) => Number(button.dataset.index) === this.swiper.activeIndex
    )
    button.classList.add('business-applicant-body__pagination-button--active')
  }
}
