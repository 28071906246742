import ApplicationController from 'controllers/rails/application_controller.js';
import 'css/app/profile.scss';
import MicroModal from "micromodal";

export default class extends ApplicationController {
  static targets = [ 'form', 'counter', 'bio' ]

  connect() {
    this.adjustBioHeight()
  }

  toggleForm(event) {
    const formSelected = this.formTargets.find(form => form.dataset.formId===event.target.dataset.formId)
    const formTargetClass = formSelected.classList
    formTargetClass.toggle('hidden')

    const htmlText = formTargetClass.contains('hidden') ? 'Mostrar' : 'Ocultar'
    event.target.innerHTML = htmlText
  }
  
  showWarning() {
    MicroModal.show('deactivate-modal')
  }
  
  closeModal() {
    MicroModal.close('deactivate-modal')
  }

  scrollToSection(event) {
    const section = document.getElementById(event.target.dataset.sectionId)
    if (section) section.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
  }

  openModal(event) {
    const modalEvent = new CustomEvent('openBorealModal', { detail: { modalId: event.target.dataset.modalId } })
    document.dispatchEvent(modalEvent)
  }

  bioChanged(){
    this.adjustBioHeight()
    const limit = Number(this.bioTarget.dataset.limit)
    var textLength = this.bioTarget.value.length
    
    this.counterTarget.textContent = `${textLength}/${limit}`
    this.toggleCounterColor(textLength, limit)
  }

  adjustBioHeight() {
    this.bioTarget.style.height = 0;
    this.bioTarget.style.height = (this.bioTarget.scrollHeight) + "px";
    if (this.bioTarget.value.length == 0 && document.activeElement === this.bioTarget) {
      this.bioTarget.style.height = "24px";
    }
  }

  toggleCounterColor(textLength, limit) {
    const counterClass = this.counterTarget.classList
    const overlimitClass = 'profile__bio-input-counter--overlimit'
    textLength > limit ? counterClass.add(overlimitClass) : counterClass.remove(overlimitClass)
  }
}