/* global Turbolinks */
import { Controller } from 'stimulus';
import './top_navbar'

export default class extends Controller {
  static targets = [ "popover" ]
  
  avatarClicked() {
    this.popoverTarget.classList.toggle('unauthenticated-top-navbar__popover--hide');
  }
}