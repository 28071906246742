import { Controller } from "stimulus";
import './toast.scss'
import anime from 'animejs/lib/anime.es.js'

export default class extends Controller {
  connect() {
    if(this.element.dataset.addedHeight) this.element.style.bottom = this.element.dataset.addedHeight
    setTimeout(() => this.close(), 3000)
  }
  
  close() {
    const height = this.element.offsetHeight
    anime({
      targets: this.element,
      bottom: `-${height}px`,
      easing: 'easeInOutSine',
      duration: 300
    })
  }
}
