import { Controller } from 'stimulus';
import ProgressBar from 'progressbar.js'

export default class extends Controller {

  static targets = [ 'container' ]
  static values = { progress: Number, text: String, color: String, strokeWidth: Number, trailWidth: Number, kind: String, showText: Boolean  } 

  connect() {
    const barValue = this.progressValue > 100 ? 100 : this.progressValue
    if (this.kindValue == "circle") {
      this.createCircle(barValue)
    } else {
      this.createLine(barValue)
    }
  }

  createCircle(barValue) {
    new ProgressBar.Circle(this.containerTarget, this.animationCircleConfig()).animate((barValue / 100))
  }

  createLine(barValue) {
    new ProgressBar.Line(this.containerTarget, this.animationLineConfig()).animate((barValue / 100))
  }

  animationCircleConfig() {
    return {
      color: this.colorValue,
      strokeWidth: this.strokeWidthValue,
      trailWidth: this.trailWidthValue,
      easing: 'easeInOut',
      duration: 1400,
      from: { color: this.colorValue, width: this.strokeWidthValue },
      to: { color: this.colorValue, width: this.strokeWidthValue },
      step: this.animationStep.bind(this)
    }
  }

  animationLineConfig() {
    return {
      strokeWidth: this.strokeWidthValue,
      easing: 'easeInOut',
      duration: 1400,
      color: this.colorValue,
      trailColor: '#eee',
      trailWidth: this.trailWidthValue,
      svgStyle: {width: '100%', height: '100%'}
    }
  }

  animationStep(state, circle) {
    circle.path.setAttribute('stroke', state.color);
    circle.path.setAttribute('stroke-width', state.width);
    if (this.showTextValue) {
      const text = this.textValue || "%"
      const finalText = this.progressValue + text
      circle.setText(finalText);
      circle.text.style.color = "black"
      circle.text.style.fontSize = ".75rem"
      circle.text.style.textAlign = "center"
    }
  }
}
