/* global Turbolinks */
import { Controller } from 'stimulus';
import MicroModal from 'micromodal';
import 'css/app/modal.scss';

export default class extends Controller {
  static targets = [ "notify" ]

  connect() {
    
  }

  start() {
    const url = this.data.get('updateProfile');
    const data = {
      notify: this.notifyTarget.checked
    };
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {'Content-Type': 'application/json'}
    }).then(response => response.json()).then(data => {});

    MicroModal.close('degree-modal');
  }
}