/* global Turbolinks */
import { Controller } from 'stimulus';
import 'css/admin/talent_filter.scss';

export default class extends Controller {

  static targets = [ 'checkbox' ]
  connect() {
  }

  selectAll() {
    for (const checkbox of this.checkboxTargets) {
      checkbox.checked = true
    }
  }
}