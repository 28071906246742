import { Controller } from 'stimulus';
import './top_navbar.scss'

export default class extends Controller {

  static targets = [ "popover" ]

  connect() {
    super.connect()
  }

  avatarClicked() {
    this.popoverTarget.classList.toggle('hunter-top-navbar__popover--hide');
  }
}
