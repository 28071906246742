import ReflexController from '../../../frontend/controllers/reflex_controller'
import './result.scss'

export default class extends ReflexController {
  static targets = [ 'displayLoading', 'displayResult' ]

  connect() {
    super.connect()
    
    this.talentFilterResultLoading = async () => this.setLoadingDisplay()
    document.addEventListener('talentFilterResultLoading', this.talentFilterResultLoading)
    
    this.talentFilterResultReady = (event) => {
      this.stimulate('Business::ResultComponent#reload_result', event.detail.items)
		}
    document.addEventListener('talentFilterResultReady', this.talentFilterResultReady)

    this.applicantDiscardedFromList = (event) => {
      this.stimulate('Business::ResultComponent#reload_total', event.detail.items)
		}
    document.addEventListener('applicantDiscardedFromList', this.applicantDiscardedFromList)
  }

  disconnect() {
    document.removeEventListener('talentFilterResultLoading', this.talentFilterResultLoading, false)
    document.removeEventListener('talentFilterResultReady', this.talentFilterResultReady, false)
    document.removeEventListener('applicantDiscardedFromList', this.applicantDiscardedFromList, false)
  }

  setLoadingDisplay() {
    this.displayResultTarget.style.display = 'none'
    this.displayLoadingTarget.style.display = 'flex'
  }

  afterReflex() {
    this.setResultDisplay()
  }

  setResultDisplay() {
    this.displayResultTarget.style.display = 'block'
    this.displayLoadingTarget.style.display = 'none'
  }
}
