import { Controller } from "stimulus";
import EasyMDE from 'easymde';
import 'easymde/dist/easymde.min.css';
import '../../../frontend/css/admin/easymde_fullscreen.scss'
import '../../../frontend/css/app/markdown_styles'
import './wysiwyg.scss'

export default class extends Controller {
  
  static targets = [ 'inputRich', 'inputField' ]

  connect() {
    this.loadEasyMDE()
  }

  loadEasyMDE(){
    this.easymdeInputRich = new EasyMDE({ 
      element: this.inputRichTarget, 
      spellChecker: false,
      sideBySideFullscreen: false,
      toolbarTips: false,
      toolbar: [
        "bold",
        "italic",
        "heading",
        "|",
        "quote",
        "unordered-list",
        "ordered-list",
        "|",
        "horizontal-rule",
        "table",
        "link",
        "|",
        "preview"
      ],
    })
    .value(this.inputFieldTarget.value)

    this.easymdeInputRich.codemirror.on("change", () => {
      this.inputFieldTarget.value = this.easymdeInputRich.value()
      const event = new Event('change');
      this.inputFieldTarget.dispatchEvent(event);
    });
  }
}
