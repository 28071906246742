import { Controller } from 'stimulus';
import './filter_by_job.scss'

export default class extends Controller {
  BUSINESS_STORAGE_POSITION = 'scroll-position-y'

  navigate(event) {
    window.sessionStorage.setItem(this.BUSINESS_STORAGE_POSITION, 0)
    const queryString = window.location.search;
    this.urlParams = new URLSearchParams(queryString);
    Turbolinks.visit(`${event.target.dataset.path}?${this.urlParams.toString()}`)
  }
}
